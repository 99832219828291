import React from "react";
import Main from "./Main/Main";

export default function App() {
  return (
    <div className="page">
      <Main/>
    </div>
  )
}
